import { Controller } from '@hotwired/stimulus'
import { isDesktop } from './utils/dev'

/*
 * Description
 * -------
 *
 * Handles comment form.
 * Can be used on any element.
 * Can be used multiple times in the document.
 *
 * Targets
 * -------
 *
 * - input  - (required) - comment input element
 * - submit - (required) - submit button
 *
 * Usage
 * -------
 *
 * <div data-controller="comment-form">
 *   <form data-action="turbo:submit-end->comment-form#reset">
 *     <input
 *       data-comment-form-target="input"
 *       data-action="input->comment-form#handleInput keypress->comment-form#handleKeypress"
 *     />
 *     <button data-comment-form-target="submit">Submit</button>
 *   </form>
 * </div>
 */

export default class extends Controller {
  static targets = ['input', 'submit']

  handleKeypress(e) {
    if (isDesktop() && e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()
      this.submitTarget.click()
    }
  }

  resetTextareaHeight() {
    this.inputTarget.style.height = ''
  }

  handleInput() {
    this.inputTarget.value.trim() ? this.showSubmit() : this.hideSubmit()
    this.resetTextareaHeight()
    this.inputTarget.style.height = this.inputTarget.scrollHeight + 'px'
  }

  showSubmit() {
    this.submitTargets.forEach((submit) => {
      submit.disabled = false
      submit.classList.remove('comment-form__post-button--hidden')
      submit.classList.add('comment-form__post-button--visible')
    })
  }

  hideSubmit() {
    this.submitTargets.forEach((submit) => {
      submit.disabled = true
      submit.classList.remove('comment-form__post-button--visible')
      submit.classList.add('comment-form__post-button--hidden')
    })
  }

  reset(event) {
    if (event.detail.success) {
      this.inputTarget.form.reset()
      this.inputTarget.blur()
      this.resetTextareaHeight()
      this.hideSubmit()
    }
  }
}
